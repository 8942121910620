@import "mixin";

/* utility
-------------------- */
@include media_mobile {
    .pconly {
        display: none !important;
    }
}
@include media_desktop {
    .sponly {
        display: none !important;
    }
}

@include media_mobile {
    div.crumbs {
        width: 100%;
    }
    body.other #box_main, body.contact #box_main, body.mypage #box_main {
        width: 100%;
        float: none;
        margin: 0;
    }
    /* ヘッダー
    ------------------------ */
    #header {
        #header_contents {
            width: 100%;
            display: flex;
            justify-content: space-between;
        }
        #header_contents:after {
            content: none;
        }
        #header_contents #header_logo {
            float: none;
            padding: 0 8px;
            img {
                height: 24px;
                width: auto;
            }
        }
        #header_menu_icon {
            padding: 0 8px;
            font-size: 24px;
        }
    }



    /* フッター
    ------------------------ */
    #contents {
        width: 100%;
    }
    #footer #footer_pagetop {
        width: 100%;
    }
    #footer #footer_contents {
        width: 100%;
    }
    #footer #footer_menu {
        width: 100%;
    }
    #footer #footer_contents #footer_contact,
    #footer #footer_contents #footer_information,
    #footer #footer_contents ul.footer_category {
        display: inline-block;
        float: none;
        width: 48%;
        margin-left: 1%;
        vertical-align: top;
        margin-right: 0;
    }
    #footer #footer_contents #footer_information p.syntax {
        width: 100%;
        margin-left: 0;
    }

    /* TOP
    ------------------------- */
    #page_homepage {
        #contents #box_top #pickup_image {
            width: 100%;
            box-sizing: border-box;
        }
        #contents #box_top #pickup_image div.product_image {
            width: 100%;
        }
        #contents #box_top #pickup_image div.product_image img {
            height: 240px;
            width: auto;
        }
        #contents #box_top #recommend {
            margin: 0;
            float: none;
        }
        #contents #box_main {
            width: 100%;
        }
        #guide {
            width: 100%;
            overflow: hidden;
        }
        #point li {
            display: block;
            margin: 0 0 16px 0;
            text-align: center;
        }
        #guide li {
            margin: 0 0 16px 0;
            width: 49.9%;
            text-align: center;
        }
        #contents #box_right {
            width: 98%;
            padding-left: 0;
            float: none;
            clear: both;
            padding: 0;
            margin: 0 auto;
        }
        #calendar {
            margin-top: 0;
            margin: 0 auto;
            width: 80%;
        }
        #calendar div.subtitle {
            background: #000;
            width: 100%;
        }
        #calendar table {
            width: 100%;
        }
        #contents #box_top #recommend div.itemlist_wrapper {
            width: 100%;
            box-sizing: border-box;
        }
        #contents #box_top #pickup_infomation {
            width: 100%;
        }
        #contents #box_top #recommend h2 {
            display: none;
        }
    }
    .page_faq #qalist, #qa  {
        margin-bottom: 16px;
    }
    .page_faq #qalist, #qa #qalist {
        width: 100%;
        box-sizing: border-box;
    }
    #qa h2 {
        background: #000;
        width: 100%;
        height: 52px;
        padding: 5px;
        box-sizing: border-box;
    }

    /* 商品一覧
    ------------------------- */
    #page_product_list  {
        .product_list_box {
            display: flex;
            flex-wrap: wrap;
        }
        div.product {
            float: none;
            width: 48%;
            margin: 0 0 24px 1%;
        }
    
    }

    /* 商品詳細
    ------------------------- */
    #page_product_detail {
        #box_main {
            width: 100%;
        }
        #contents #product_detail #images {
            width: 100%;
            float: none;
        }
        #contents #product_detail #image {
            width: 100%;
            box-sizing: border-box;
        }
        #contents #product_detail #image div.product_image {
            width: 100%;
        }
        #contents #product_detail {
            width: 100%;
        }
        #contents #product_detail #product_name {
            width: 100%;
            float: none;
        }
        #contents #product_detail div.info {
            width: 100%;
            box-sizing: border-box;
            float: none;
        }
        #contents #product_detail div.certificate {
            width: 45%;
        }
        #contents #product_detail div.action {
            width: 56%;
        }
        #contents #product_detail #product_name {
            background: #cc0f71;
        }
        #contents #product_detail div.action select {
            width: 90%;
        }
    }
    
    #recommend_horizon {
        width: 100%;
        display: none;
    }
    #recommend_horizon h2.subtitle {
        display: none;
    }
    #search_bottom {
        width: 100%;
        div.search_keyword {
            width: 100%;
            display: flex;
            h3 {
                width: 40%;
                text-align: center;
            }
            > .controls {
                width: 40%;
                text-align: center;
                input {
                    width: 100%
                }
            }
            .button {
                width: 20%;
                height: auto;
                text-align: center;
            }
        }

    }

    /* cart
    ---------------------- */
    #page_cart {
        .title {
            width: 100%;
            padding: 7px 0 0 0;
        }
        .carttable {
            width: 100%;
        }
        .ec-progress__label {
            display: none;;
        }
        @include action_button();

    }
    /* login
    --------------------- */
    #page_mypage_login,
    #page_shopping_login {
        .title {
            width: 100%;
            padding: 7px 0 0 0;
        }
        #shopping_login_block {
            width: 100%;
        }
        #login_block {
            width: 90%;
            float: none;
            margin: 0 auto 16px auto;
            h3 img {
                width: 100%;
            }
            input[type="email"], input[type="password"] {
                width: 100%;
            }
        }
        #noaccount_block {
            width: 90%;
            float: none;
            padding: 5px;
            margin: 0 auto;
            h3 img {
                width: 100%;
            }
        }
    }
    /* ゲスト購入
    ----------------------------- */
    #page_shopping_nonmember {
        .guest_title {
            width: 100%;
            padding: 7px 0 0 0;
        }
        .ec-cartRole {
            display: none;
        }
        .registtable {
            width: 100%;
        }
        .box380 {
            width: 320px;
        }
        @include action_button();
    }
    /* 注文画面
    --------------------------- */
    #page_shopping_confirm, #page_shopping {

        .title {
            width: 100%;
            padding: 7px 0 0 0;
        }
        .ec-cartRole {
            display: none;
        }
        .carttable {
            width: 100%;
        }
        .infortable {
            width: 100%;
        }
        .ec-orderPayment table,
        .ec-orderDelivery table,
        .ec-orderConfirm table {
            width: 100%;
        }
        #btn_block {
            width: 100%;
            box-sizing: border-box;
        }
        @include action_button();
        #check_rule {
            width: 100%;
            box-sizing: border-box;
        }
    }
    #page_shopping_shipping, #page_shopping_shipping_edit {
        @include action_button();
        .title {
            width: 100%;
            overflow: hidden;
        }
        
    }

    /* 注文完了
    ------------------------ */
    #page_shopping_complete {
        .title {
            width: 100%;
        }
        .ec-cartRole {
            display: none;
        }
        #complete_area {
            width: 100%;
            box-sizing: border-box;
        }
        #btn_block {
            width: 100%;
            box-sizing: border-box;
        }
    }
    /* サイドメニュー
    -------------------------- */
    body.other,
    body.contact,
    body.mypage,
    #page_product_list,
    #page_entry,
    #page_entry_activate,
    #page_forgot {
        #box_left {
            display: none;
        }
        #box_main {
            width: 100%;
            float: none;
            margin-left: 0px;
        }
    }
    body.contact h2.title, body.other h2.title, body.mypage h2.title {
        width: 100%;
    }

    #page_contact .box380 {
        width: 320px;
    }

    /* 会員登録完了
    ---------------------- */
    h2#title_entry {
        width: 100%;
    }
    div.complete_box #complete_area {
        width: 100%;
        box-sizing: border-box;
    }
    #page_mypage_history .ec-orderMail__body {
        word-break: break-all;
    }

    /* modal
    ---------------------- */
    div.block_guide div.guide_deliv_map {
        margin-top: 0;
        margin-left: 0;
        img {
            width: 98%;
        }
    }
    div.block_guide .guid_item_recycle > div {
        margin-top:10px;
        width: 100%;
    }
    .simplemodal-data {
        width: 98%;
    }
    div.block_guide .deliv_fee {
        margin-bottom: 16px;
    }


    #search_bottom div.search_price, #search_bottom div.search_keyword {
        margin-left: 0;
    }
    .guide_modal_link img {
        width: 7em;
        height: auto;
    }

}

/*
ヘッダー：SPヘッダー

SP時のみ出現するヘッダーに関係するコンポーネントです。<br>
ex [トップページ](http://demo3.ec-cube.net/)画面サイズが768px以下に該当。<br>
<br>
`.ec-drawerRole`：SPのドロワー内の要素をwrapするコンポーネントです。<br>
`.ec-headerSearch`、`.ec-headerNav`、`.ec-itemNav`は`.ec-drawerRole`の子要素にある場合、ドロワーに適したスタイルに変化します。<br><br>
`.ec-overlayRole`：SPのドロワー出現時にz-indexがドロワー以下の要素に半透明の黒背景をかぶせるコンポーネントです。<br>

Styleguide 11.2.6
*/

.ec-drawerRole {
    overflow-y: scroll;
    background: black;
    width: 260px;
    height: 100vh;
    transform: translateX(-300px);
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1;
    transition: z-index 0ms 1ms;
    @include media_desktop() {
        display: none;
    }


    .searchform {
        padding: 20px 10px;
        width: 100%;
        background: #fff;
        .search-name {
            width: 144px;
            height: 30px;
        }
        > * {
            vertical-align: middle;
        }
    }

    .ec-itemNav {
        margin: 0;
        padding: 0;
        width: 100%;
        height: 100%;
        text-align: center;
        }
        
    .ec-itemNav__nav {
        display: block;
        margin: 0 auto;
        padding: 0;
        width: auto;
        height: auto;
        list-style-type: none;
        text-align: center;
        vertical-align: bottom;
        @include media_desktop {
            display: inline-block;
        }
    }
    
    .ec-itemNav__nav li {
        float: none;
        margin: 0;
        padding: 0;
        width: 100%;
        text-align: center;
        position: relative;
        @include media_desktop {
            float: left;
            width: auto;
        }
    }
    
    .ec-itemNav__nav li a {
        display: block;
        border-bottom: 1px solid #E8E8E8;
        margin: 0;
        padding: 16px;
        height: auto;
        color: #2e3233;;
        font-size: 16px;
        font-weight: bold;
        line-height: 20px;
        text-decoration: none;
        text-align: left;
        background: #fff;
        border-bottom: 1px solid #E8E8E8;
        @include media_desktop {
            text-align: center;
            border-bottom: none;
        }
    }
    
    .ec-itemNav__nav li ul {
        display: none;
        z-index: 0;
        margin: 0;
        padding: 0;
        min-width: 200px;
        list-style: none;
        position: static;
        top: 100%;
        left: 0;
        @include media_desktop {
            display: block;
            z-index: 100;
            position: absolute;
        }
    }
    
    .ec-itemNav__nav li ul li {
        overflow: hidden;
        width: 100%;
        height: auto;
        transition: .3s;
        @include media_desktop {
            overflow: hidden;
            height: 0;
        }
    }
    
    .ec-itemNav__nav li ul li a {
        border-bottom: 1px solid #E8E8E8;
        padding: 16px 22px 16px 16px;
        font-size: 16px;
        font-weight: bold;
        color: white;
        text-align: left;
        background: black;
    }
    
    .ec-itemNav__nav > li:hover > a {
        background: #fafafa;
    }
    
    .ec-itemNav__nav > li:hover li:hover > a {
        background: #333;
    }
    
    .ec-itemNav__nav > li:hover > ul > li {
        @include media_desktop {
            overflow: visible;
            height: auto;
        
        }
    }
    
    .ec-itemNav__nav li ul li ul {
        top: 0;
        left: 100%;
        width: auto;
    }
    
    .ec-itemNav__nav li ul li ul:before {
        @include media_desktop {
            content: "\f054";
            font-family: "Font Awesome 5 Free";
            font-weight: 900;
            font-size: 12px;
            color: white;
            position: absolute;
            top: 19px;
            right: auto;
            left: -20px;
        }
    }
    
    .ec-itemNav__nav li ul li:hover > ul > li {
        @include media_desktop {
            overflow: visible;
            height: auto;
            width: auto;
        }
    }
    
    .ec-itemNav__nav li ul li ul li a {
        background: #7D7D7D
    }
    
    .ec-itemNav__nav li:hover ul li ul li a:hover {
        background: #333;
    }
        
    .ec-headerCategoryArea{
        .ec-headerCategoryArea__heading {
            border-top: 1px solid #CCCCCC;
            border-bottom: 1px solid #CCCCCC;
            padding: 1em 10px;
            font-size: 16px;
            font-weight: bold;
            color: black;
            background: #F8F8F8;
        }
    
        p {
            margin-top: 0;
            margin-bottom: 0;
        }
    
        .ec-itemNav__nav li a {
            border-bottom: 1px solid #ccc;
            border-bottom: 1px solid #ccc;
            color: black;
            font-weight: normal;
            background: #f8f8f8;
        }
    
        .ec-itemNav__nav li ul li a {
            border-bottom: 1px solid #ccc;
            padding-left: 20px;
            font-weight: normal;
            background: white;
        }
    
        .ec-itemNav__nav > li:hover > a {
            background: #f8f8f8;
        }
    
        .ec-itemNav__nav > li:hover li:hover > a {
            background: white;
        }
    
        .ec-itemNav__nav li ul li ul li a {
            padding-left: 40px;
            color: black;
            background: white;
        }
    
        .ec-itemNav__nav li:hover ul li ul li a:hover {
            background: white;
        }
    
        .ec-itemNav__nav li ul li ul li ul li a{
            padding-left: 60px;
            font-weight: normal;
        }
    }
    .ec-headerLinkArea {
        background: black;
    
        .ec-headerLink__list {
            border-top: 1px solid #ccc;
    
        }
    
        .ec-headerLink__item {
            display: block;
            border-bottom: 1px solid #ccc;
            padding: 15px 20px;
            font-size: 16px;
            font-weight: bold;
            color: white;
        }
        .ec-headerLink__icon {
            display: inline-block;
            width: 28px;
            font-size: 17px;
        }
    }
}

.ec-drawerRoleClose {
    display: none;
    cursor: pointer;
    border-radius: 50%;
    box-sizing: border-box;
    padding: 10px;
    width: 40px;
    height: 40px;
    font-size: 18px;
    text-align: center;
    color: black;
    background: white;
    position: fixed;
    top: 10px;
    left: 270px;
    z-index: 1000;

    .fas {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }
    @include media_desktop {
        display: none;
    }

}

.ec-drawerRole.is_active {
    display: block;
    transform: translateX(0);
    transition: all .3s;
    z-index: 100000;

    @include media_desktop() {
        display: none;
    }
}
.ec-drawerRoleClose.is_active  {
    display: inline-block;
    transition: all .3s;

    @include media_desktop {
        display: none;
    }
}

.ec-overlayRole {
    position: fixed;
    width: 100%;
    height: 100vh;
    top: 0;
    left: 0;
    opacity: 0;
    background: transparent;
    transform: translateX(0);
    transition: all .3s;
    visibility: hidden;

    @include media_desktop {
        display: none;
    }
}

.have_curtain .ec-overlayRole {
    display: block;
    opacity: 1;
    background: rgba(0, 0, 0, 0.5);
    visibility: visible;

    @include media_desktop {
    display: none;
    }
}



/*
カート

カート 注文詳細 に関する Project コンポーネントを定義します。

ex [カートページ](http://demo3.ec-cube.net/shopping)

(カート内に商品がある状態でアクセス)

Markup:
include /assets/tmpl/elements/15.1.cart.pug
+ec-cartRole

Styleguide 15.1

*/
