@charset "utf-8";

#page_shopping,
#page_shopping_confirm {

  .pay_area {
    margin: 30px 0;
  }

  .ec-orderPayment,
  .ec-orderDelivery,
  .ec-orderConfirm {
    h3 {
      background: url("../img/common/icon_prefix_square4.gif") no-repeat 0 center;;
      padding-left: 13px;
    }

    .title {
      width: 940px;
      height: 31px;
      background: url("../img/shopping_payment/title.gif");
      text-align: right;
      padding: 7px 10px 0 0;
    }

    #shopping_payment_block {
      width: 950px;
      overflow: hidden;
      margin-top: 15px;
    }

    #btn_block {
      clear: both;
      width: 730px;
      padding: 15px 0 10px 0;
      overflow: hidden;
      background-color: #E1E1E1;
      margin: 40px auto 20px auto;
    }

    #btn_block ul {
      display: block;
      width: 530px;
      margin: 0 auto;
    }

    #btn_block ul li {
      float: left;
      margin: 0 20px;
    }

    span.attention {
      font-weight: bold;
      color: #ee446f;
    }

    table {
      width: 950px;
      background-color: #E1E1E1;
      border-collapse: separate;
      border-spacing: 0 5px;
      border: solid 5px #E1E1E1;
      border-width: 0 5px 0 5px;
    }

    table th,
    table td {
      background-color: white;
      padding: 10px;
    }

    table th {
      font-size: 14px;
      width: 110px;
    }

    table td {
      padding-left: 15px;
      background: url("../img/shopping_login/td_bg.gif") repeat-y 0 0 white;
      border: solid 3px white;
      border-width: 4px 0;
    }

    .deliv_msg {
      width: 100%;
      padding-top: 10px;
      margin-top: 10px;
      background: url('../img/common/border_footer.png') repeat-x;
    }

    span.color {
      color: #ee446f;
      font-size: 20px;
    }
    div.order_receipt_name_area {
      display: none;
      margin-top: 10px;
    }
    div.order_receipt_name_memo {
      display: none;
      margin-top: 10px;
    }
  }
}