#page_homepage #contents_wrapper {
	background: url('../img/index/background_main.png') repeat-x;
}

#page_homepage #contents #box_top {
	margin-bottom: 20px;
}
#page_homepage #contents #box_top #pickup_image {
	background-color: #ffffff;
	float: left;
	width: 440px;
	height: 331px;
	border: solid 1px #cc0f71;
	position: relative;
}
#page_homepage #contents #box_top #pickup_image img.pickup_mark {
	z-index: 99;
	position: absolute;
	top: 0px;
	left: 0px;
}
#page_homepage #contents #box_top #pickup_image div.product_image {
	z-index: 98;
	position: absolute;
	top: 0;
	left: 0;
	height: 331px;
	width: 440px;
	overflow: hidden;
}
#page_homepage #contents #box_top #pickup_image div.product_image p {
	line-height: 300px;
	text-align: center;
}
#page_homepage #contents #box_top #pickup_image div.product_image img {
	vertical-align: bottom;
	width: 74%;
	height: auto;
}

#page_homepage #contents #box_top #pickup_image div.detail_button {
	position: absolute;
	bottom: 5px;
	right: 5px;
	z-index: 103;
}
#page_homepage #contents #box_top #pickup_infomation {
	float: left;
	width: 510px;
	height: 57px;
	padding-top: 3px;
	overflow: hidden;
	margin-top: 5px;
}
#page_homepage #contents #box_top #pickup_infomation p {
	color: #ffffff;
	padding: 0 0 0 20px;
}

#page_homepage #contents #box_top #recommend {
	margin: 11px 0 0 10px;
	float: left;
}
#page_homepage #contents #box_top #recommend:after {
    display: block;
    clear: both;
    height: 0px;
    line-height: 0px;
    visibility: hidden;
    content: ".";
}
#page_homepage #contents #box_top #recommend h2{
	float: left;
}
#page_homepage #contents #box_top #recommend ul.pagenate {
	padding: 7px 5px 0 0;
	float: right;
}
#page_homepage #contents #box_top #recommend div.itemlist_wrapper {
	background-color: #ffffff;
	clear: both;
	height: 226px;
	width: 500px;
	overflow: hidden;
	border: solid 1px #323232;
}

#page_homepage #contents #box_main {
	width: 630px;
	float: left;
}
#page_homepage #contents #box_right {
	width: 310px;
	padding-left: 15px;
	float: right;
}
#page_homepage #point {
}
#page_homepage #point li {
	display: inline-block;
}
#page_homepage #guide li {
	display: inline-block;
}
#page_homepage #point li {
	margin-left: 5px;
	margin-top: 5px;
}
#page_homepage #point li.first {
	margin-left: 0;
	clear: both;
}
#page_homepage #point li.top {
	margin-top: 0;
}
#page_homepage #guide {
	margin-top: 10px;
}
#page_homepage #guide h2 {
	margin-top: 10px;
}
#page_homepage #guide ul {
	margin-top: 10px;
}
#page_homepage #guide li {
	margin-left: 10px;
}
#page_homepage #guide li.first {
	margin-left: 0px;
}

#page_homepage #calendar {
	margin-top: 20px;
}
#page_homepage #calendar div.subtitle {
	background: url('../img/common/title_black_02.png') no-repeat;
	width: 307px;
	height: 41px;
	padding: 5px 0 0 0;
	text-align: center;
	color: #ffffff;
}

#page_homepage #calendar table {
	border-collapse: collapse;
	margin-top: -16px;
}
#page_homepage #calendar th,
#page_homepage #calendar td {
	width: 42px;
	text-align: center;
}
#page_homepage #calendar th {
	border-left: solid 1px #323232;
	border-right: solid 1px #323232;
	border-bottom: solid 1px #323232;
	height: 18px;
	background-color: #f0f0f0;
}
#page_homepage #calendar th.sun {
	color: #cc0f71;
}
#page_homepage #calendar td {
	border: solid 1px #323232;
	height: 23px;
}
#page_homepage #calendar td.holiday {
	background: url('../img/common/background_holiday.png');
}
#page_homepage #calendar td.today {
	font-weight: bold;
	font-size: 14px;
	border: solid 2px #323232;
}