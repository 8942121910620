@charset "utf-8";

/************************************************
 共通設定
************************************************ */
body {
    color: #000;
    font-family: Verdana,Arial,Helvetica,sans-serif;
    background-color: #ffffff;
    font-size: 13px; /* min */
    line-height: 150%;
}

.point_font_color {
	color: #ee446f;
}
.point_border_color {
	color: #cc0f71;
}
.bold {
	font-weight: bold;
}

/* ==============================================
 カラム指定
=============================================== */
/* ヘッダーとフッターの上下
[注意]ブロック移動時はbloc.css内で調整が必要 */
#topcolumn,
#bottomcolumn,
#footerbottomcolumn {
    margin: 0 auto;
    padding: 15px;
    width: 950px;
    background: #fff;
    text-align: left;
}

/* 中央カラム */
.main_column {
    padding: 10px 0 20px;
}

/* サイドカラム */
#leftcolumn {
    float: left;
    width: 20%;
}
#rightcolumn {
    float: right;
    width: 20%;
}

/* 1カラム設定 */
#one_maincolumn {
    margin: 0 auto;
    width: 80%;
}

/* 2カラム設定 (メイン部が左) */
#two_maincolumn_left {
    padding-left: 1.5%;
    float: left;
    width: 78%;
}

/* 2カラム設定 (メイン部が右) */
#two_maincolumn_right {
    padding-right: 1.5%;
    width: 78%;
    float: right;
}

/* 3カラム設定 */
#three_maincolumn {
    padding-left: 0.5%;
    width: 59%;
    float: left;
}

/* 下層コンテンツ */
#undercolumn {
    width: 100%;
    margin: 0 0 30px 0;
}


/* ==============================================
 ユーティリティ
=============================================== */
/* フロート回り込み解除
----------------------------------------------- */
.clearfix:after {
    display: block;
    clear: both;
    height: 0px;
    line-height: 0px;
    visibility: hidden;
    content: ".";
}
.clearfix {
    display: block; /* for IE8 */
}
.clear {
    clear: both;
}
.price span {
	color: #ee446f;
}


div.attention,
p.attention,
span.attention,
.ec-errorMessage {
  font-weight: bold;
  color: #ee446f;
}

.box120 {
	width: 120px;
}
.box60 {
	width: 60px;
}
.box300 {
	width: 300px;
}
.box380 {
	width: 380px;
}

div.btn_area {
	text-align: center;
	padding: 10px 0 10px 0;
	background-color: #E1E1E1;
}
div.btn_area li {
	display: inline;
}
#box_left div.block h2.subtitle {
	background: url('../img/common/title_black_01_left.png') no-repeat;
	width: 246px;
	height: 35px;
	padding: 4px 0 0 10px;
}
#box_left div.block div.block_inner {
	margin-left: 5px;
	width: 251px;
	margin-top: -9px;
	background-color: #f0f0f0;
}

#box_left h2.subtitle {
	background: url('../img/common/title_black_01_left.png') no-repeat;
}
.alignC {
	text-align: center;
}
.alignR {
	text-align: right;
}
.alignL {
	text-align: left;
}
input[type=image]:hover,
a:hover img {
	opacity:0.7;
	filter:alpha(opacity=70);
	-ms-filter: "alpha( opacity=70 )";
}
img.autozip_button_image {
	vertical-align: middle;
}
/* form parts
----------------------------------------------- */
input {
	/*border: solid 1px #858585;*/
}

/* リンク指定
----------------------------------------------- */
a:link,
a:visited {
    color: #000;
    text-decoration: underline;
}
a:link:hover,
a[href]:hover {
    color: #ee446f;
    text-decoration: underline;
}

/* #header
----------------------------------------------- */
#header {
	width: 100%;
	border-top: #848484 solid 4px;
	margin-bottom: 15px;
}
#header #header_contents {
	width: 955px;
	margin: 10px auto 5px auto;
    display: block;
	position: relative;
}
#header #header_contents:after {
    display: block;
    clear: both;
    height: 0px;
    line-height: 0px;
    visibility: hidden;
    content: ".";
}
#header #header_contents #header_logo {
	width: auto;
	float: left;
}
#header #header_contents #header_search {
	width: auto;
	float: right;
	line-height: 38px;
	margin-right: 10px;
}
#header #header_contents #header_search * {
	vertical-align: bottom;
}
#header #header_contents #header_search input {
	width: 180px;
	height: 30px;
	margin-right: 7px;
	font-size: 14px;
	padding-left: 5px;
}
#header #header_contents #header_menu {
	width: auto;
	float: right;
	line-height: 38px;
}
#header #header_contents #header_menu li {
	padding-left: 23px;
	height: 20px;
	display: inline-block;
	margin-left: 5px;
	vertical-align: bottom;
	line-height: 100%;
}
#header #header_contents #header_menu li a {
	vertical-align: bottom;
}

#header #header_contents #header_menu li#header_menu_login {
	background: url('../img/common/icon_login.png') no-repeat left -3px;
}
#header #header_contents #header_menu li#header_menu_cart {
	background: url('../img/common/icon_cart.png') no-repeat left -3px;
}
#header #header_contents #header_menu li#header_menu_contact {
	background: url('../img/common/icon_contact.png') no-repeat left -3px;
}
#header #header_contents #header_menu li#header_menu_faq {
	background: url('../img/common/icon_question.png') no-repeat left 0px;
}
#header #header_infomation {
	background-color: #313131;
}
#header #header_infomation_contents {
	clear: both;
	width: 955px;
	margin: 0 auto 0 auto;
    display: block;
	position: relative;
}
#header #header_infomation_contents:after {
    display: block;
    clear: both;
    height: 0px;
    line-height: 0px;
    visibility: hidden;
    content: ".";
}

#header #header_infomation_contents ul {
	width: auto;
	float: left;
}
#header #header_infomation_contents ul li {
	display: inline;
}
#header #header_infomation_contents ul li.ad_banner {
    margin-left: 16px;
}
#header #header_infomation_contents #header_category {
	position: absolute;
	width: auto;
	top: 0;
	right: 0;
	z-index: 998;
}
#header #header_infomation_contents #header_category div#header_category_list_box {
	background: rgba(0,0,0,0.84);
	padding: 10px 10px 5px 20px;
	filter: progid:DXImageTransform.Microsoft.gradient(startcolorstr=#CA000000, endcolorstr=#CA000000, gradienttype=0);
	display: none;
	height: 220px;
 }
#header #header_infomation_contents #header_category div#header_category_list_box:after {
    display: block;
    clear: both;
    height: 0px;
    line-height: 0px;
    visibility: hidden;
    content: ".";
}
#header_category_button_area a.off {
	background: url('../img/common/button_header_category_off.png') no-repeat;
}
#header_category_button_area a.on {
	background: url('../img/common/button_header_category_on.png') no-repeat;
}
#header_category_button {
	height: 35px;
	width: 138px;
	display: block;
	float: right;
	text-indent: -9999px;
}
/* #contents
----------------------------------------------- */
#contentsWrapper {
	width: 100%;
	clear: both;
}
#contentsWrapper:after {
    display: block;
    clear: both;
    height: 0px;
    line-height: 0px;
    visibility: hidden;
    content: ".";
}
#contents {
	clear: both;
	width: 955px;
	margin: 0 auto 0 auto;
    display: block;
}
#contents:after {
    display: block;
    clear: both;
    height: 0px;
    line-height: 0px;
    visibility: hidden;
    content: ".";
}
#contents #box_top:after {
    display: block;
    clear: both;
    height: 0px;
    line-height: 0px;
    visibility: hidden;
    content: ".";
} 
#contents #box_left:after {
    display: block;
    clear: both;
    height: 0px;
    line-height: 0px;
    visibility: hidden;
    content: ".";
}
#contents #box_main:after {
    display: block;
    clear: both;
    height: 0px;
    line-height: 0px;
    visibility: hidden;
    content: ".";
}
#contents #box_right:after {
    display: block;
    clear: both;
    height: 0px;
    line-height: 0px;
    visibility: hidden;
    content: ".";
}

/* #footer
----------------------------------------------- */
#footer {
	color: #ffffff;
	margin-top: 20px;
}
#footer a:link ,
#footer a:visited ,
#footer a {
	color: #ffffff;
    text-decoration: none;
}
#footer a:hover {
	color: #ee446f;
    text-decoration: underline;
}
#footer #footer_pagetop {
	width: 955px;
	clear: both;
	margin: 0 auto 0 auto;
}
#footer #footer_pagetop p {
	text-align: right;
	line-height: 0;
}
#footer #footer_contents_wrapper {
	background: #323232 url('../img/common/border_footer.png') repeat-x;
	padding-bottom: 10px;
}
#footer #footer_contents {
	width: 955px;
	clear: both;
	margin: 0 auto 20px auto;
	padding-top: 20px;
}
#footer #footer_contents:after {
    display: block;
    clear: both;
    height: 0px;
    line-height: 0px;
    visibility: hidden;
    content: ".";
}
#header #header_infomation_contents #header_category ul.header_category,
#footer #footer_contents ul.footer_category {
	display: block;
	float: left;
	width: auto;
	margin-right: 25px;
}
#header #header_infomation_contents #header_category ul.header_category li,
#footer #footer_contents ul.footer_category li {
	padding-left: 10px;
	background: url('../img/common/icon_arrow.png') no-repeat 0 center;
	margin-bottom: 12px;
	display: block;
	clear: both;
	color: #ffffff;
}
#header #header_infomation_contents #header_category ul.header_category li a {
	color: #ffffff;
}
#footer #footer_contents #footer_information {
	display: block;
	float: right;
	width: 165px;
}
#footer #footer_contents #footer_information p.syntax {
	width: 140px;
	margin-left: 20px;
}
#footer #footer_contents #footer_information p.logo {
	margin-top: 15px;
}
#footer #footer_contents #footer_contact {
	display: block;
	width: auto;
	float: right;
	margin-right: 10px;
}
#footer #footer_contents #footer_contact li {
	margin-bottom: 15px;
}
#footer #footer_contents #footer_contact span.number {
	font-size: 20px;
	font-weight: bold;
}

#footer #footer_menu {
	padding-top: 20px;
	width: 955px;
	clear: both;
	margin: 0 auto 0 auto;
	background: #323232 url('../img/common/border_footer_menu.png') repeat-x;
}
#footer #footer_menu ul {
	display: inline-block;
}
#footer #footer_menu li {
	display: inline-block;
	border-left: 1px solid #ffffff;
	padding: 0 15px 0 15px;
}
#footer #footer_menu li.first {
	padding: 0 15px 0 0;
	border-left: none;
}
#footer #footer_menu li#copyright {
	color: #ffffff;
	clear: both;
	padding: 0;
	border-left: none;
	margin-top: 5px;
	font-size: 10px;
}

/* .pagenate
----------------------------------------------- */
ul.pagenate li{
	display: inline-block;
	vertical-align: middle;
	margin-left: 3px;
}
ul.pagenate li.page{
	width: 10px;
	height: 10px;
	border: solid 1px #858585;
	background-color: #ffffff;
}
ul.pagenate li.current{
	background-color: #cc0f71;
}

/* #cartin
----------------------------------------------- */
#cartin {
	margin-left: 5px;
	width: 231px;
	background-color: #f0f0f0;
	padding: 5px 10px 10px 10px;
	margin-bottom: 20px;
}
#cartin h2{
	padding-top: 5px;
	padding-bottom: 8px;
	text-align: center;
}
#cartin .quantity {
	font-size: 14px;
	margin-bottom: 7px;
}
#cartin .price {
	padding-top: 8px;
	font-size: 14px;
	background: url('../img/common/border_footer.png') repeat-x;
}
#cartin .price span{
	font-weight: bold;
}
#cartin .detail {
	margin-top: 10px;
	text-align: center;
}

/* #category
----------------------------------------------- */
#category ul {
	padding: 10px 0 10px 10px;
}
#category ul li {
	margin-bottom: 5px;
	padding-left: 10px;
	background: url('../img/common/icon_arrow.png') no-repeat left 6px;
	font-weight: normal;
	color: #323232;
}
#category ul li ul {
	padding: 0 0 0 10px;
}
#category ul li.parent a {
}
#category ul li.children {
	background: none;
}
#category ul li.current a {
	color: #ee446f;
	font-weight: bold;

}
#category ul li.current ul a {
	font-weight: normal;
	color: #323232;
}

/* #recommend
----------------------------------------------- */
div.recommend ul.itemlist {
	width: 100%;
	background-color: #ffffff;
}
#page_homepage #contents #box_top #recommend ul.itemlist {
	/*padding: 5px 0 5px 0;*/
	height: 226px;
	width: 900px;
}
div.recommend ul.itemlist li {
	border: solid 2px #f0f0f0;
	padding: 2px;
}
#page_homepage #contents #box_top #recommend ul.itemlist li {
	display: inline-block;
	width: 166px;
	border-right: dashed 1px #323232;
	height: 226px;
	margin: 0;
	padding: 0;
}
div.recommend ul.itemlist li p,
#page_homepage #contents #box_top #recommend ul.itemlist li p {
	margin-left: 5px;
}
div.recommend ul.itemlist li p.center,
#page_homepage #contents #box_top #recommend ul.itemlist li p.center {
	margin-left: 0;
	text-align: center;
}
div.recommend ul.itemlist li p.name,
#page_homepage #contents #box_top #recommend ul.itemlist li p.name {
	height: 39px;
	overflow: hidden;
}
div.recommend ul.itemlist li p.photo,
#page_homepage #contents #box_top #recommend ul.itemlist li p.photo {
	width: 165px;
	height: 140px;
	overflow: hidden;
	line-height: 140px;
}
div.recommend ul.itemlist li p.photo {
	text-align: center;
	width: 100%;
}
div.recommend ul.itemlist li p.photo img,
#page_homepage #contents #box_top #recommend ul.itemlist li p.photo img {
	vertical-align: middle;
	width: 90%;
	height: auto;
}
#recommend_side ul.itemlist li p.photo img {
	width: auto;
	height: 90%;
}
div.recommend ul.itemlist li p.detail,
#page_homepage #contents #box_top #recommend ul.itemlist li p.detail {
	margin-top: 7px;
}
div.recommend ul.itemlist li p.detail {
	text-align: right;
}

div.recommend div.info {
	background-color: #f0f0f0;
	padding: 5px;
}


#recommend_horizon {
	clear: both;
	height: 175px;
	overflow: hidden;
	width: 955px;
	background: url('../img/common/background_recommend_horizon.png') repeat-x;
}
#recommend_horizon h2.subtitle, 
#recommend_horizon div.prev, 
#recommend_horizon div.next, 
#recommend_horizon div.itemlist_wrapper {
	float: left;
	width: auto;
}
#recommend_horizon h2.subtitle {
	margin-top: 25px;
}
#recommend_horizon div.prev {
	margin-top: 75px;
	margin-left: 8px;
} 
#recommend_horizon div.next {
	margin-top: 75px;
}

#recommend_horizon div.itemlist_wrapper {
	height: 172px;
	width: 760px;
	overflow: hidden;
}
#recommend_horizon ul.itemlist {
	height: 226px;
	width: 900px;
	padding: 8px 0 8px 0;
}
#recommend_horizon ul.itemlist li {
	display: inline-block;
	border-right: dashed 1px #323232;
	height: 210px;
	margin: 0;
}
#recommend_horizon ul.itemlist li:after {
    display: block;
    clear: both;
    height: 0px;
    line-height: 0px;
    visibility: hidden;
    content: ".";
}
#recommend_horizon ul.itemlist li div.left {
	width: auto;
	float: left;
	margin-left: 3px;
}
#recommend_horizon ul.itemlist li div.right {
	width: 111px;
	float: left;
	padding-right: 5px;
}
#recommend_horizon ul.itemlist li p {
	margin-left: 2px;
}
#recommend_horizon ul.itemlist li p.center {
	margin-left: 0;
	text-align: center;
}
#recommend_horizon ul.itemlist li p.photo {
	width: 129px;
	height: 133px;
	overflow: hidden;
	line-height: 140px;
	border: solid 2px #f0f0f0;
}
#recommend_horizon ul.itemlist li p.photo img {
	vertical-align: middle;
	max-width: 120px;
}
#recommend_horizon ul.itemlist li p.detail {
	margin-top: 5px;
}
#recommend_horizon ul.itemlist li p.price {
	text-align: right;
}
#recommend_horizon ul.itemlist li p.certificate select {
	width: 104px;
}
#recommend_horizon ul.itemlist li p.quantity select {
	width: 104px;
}
/* #search_bottom
----------------------------------------------- */
#search_bottom {
	clear: both;
	width: 955px;
	background: url('../img/common/border_footer.png') repeat-x left top;
	padding: 10px 0 0 0
}
#search_bottom div.block_inner {
	width: 100%;
	background: url('../img/common/border_footer.png') repeat-x left bottom;
	margin-top: 0px;
	padding: 0 0 10px 0
}
#search_bottom div.block_inner:after {
    display: block;
    clear: both;
    height: 0px;
    line-height: 0px;
    visibility: hidden;
    content: ".";
}
#search_bottom h2 {
	width: 955px;
	background: url('../img/common/icon_prefix_square3.png') no-repeat left 50%;
	padding-left: 15px;
}

#search_bottom h2 {
	width: auto;
	float: left;
	margin-left: 10px;
}
#search_bottom div.search_price,
#search_bottom div.search_keyword {
	width: auto;
	float: left;
	margin-left: 60px;
}
#search_bottom div.search_keyword h3,
#search_bottom div.search_keyword div.controls,
#search_bottom div.search_keyword div.button,
#search_bottom div.search_price h3,
#search_bottom div.search_price div.controls,
#search_bottom div.search_price div.button {
	width: auto;
	float: left;
}
#search_bottom div.search_keyword h3,
#search_bottom div.search_keyword div.controls,
#search_bottom div.search_price h3,
#search_bottom div.search_price div.controls {
	margin-left: 7px;
}

#search_bottom div.search_keyword div.button,
#search_bottom div.search_price div.button {
	margin-left: 10px;
}

#search_bottom h2 {
	margin-top: 14px;
	font-size: 16px;
}
#search_bottom div.search_price div.button,
#search_bottom div.search_keyword div.controls,
#search_bottom div.search_keyword div.button,
#search_bottom div.search_keyword h3,
#search_bottom div.search_price h3 {
	margin-top: 13px;
}
#search_bottom div.search_keyword h3,
#search_bottom div.search_price h3 {
	font-size: 16px;
}

/* ご利用ガイド
----------------------------- */
div.block_guide div.guide_item {
	margin-top: 16px;
}
div.block_guide h3 {
	padding-left: 12px;
	background: url('../img/common/icon_prefix_square4_pink.png') no-repeat left 50%;
}
div.block_guide div.guide_item dt {
	font-weight: bold;
}
div.block_guide div.guide_item dd {
	margin-top: -1.5em;
	margin-left: 60px;
}
div.block_guide div.deliv_date_summary {
	width: 340px;
	border: 3px solid #f0f0f0;
	padding: 3px;
}
div.block_guide div.deliv_date_summary h4 {
	margin-bottom: 5px;
}

div.block_guide li.li_deliv_date {
	margin-bottom: 5px;
}
div.block_guide li.li_deliv_date div.icon_deliv {
	height: 1.2em;
	width: 1.2em;
	overflow: hidden;
	
}
div.block_guide li.li_deliv_date div.icon_deliv1 {
	background-color: #2F0DBC;
}
div.block_guide li.li_deliv_date div.icon_deliv2 {
	background-color: #710E7A;
}
div.block_guide li.li_deliv_date div.icon_deliv3 {
	background-color: #CD0F71;
}
div.block_guide li.li_deliv_date div.icon_deliv4 {
	background-color: #FF7C10;
}
div.block_guide li.li_deliv_date div.icon_deliv5 {
	background-color: #0EA9C8;
}
div.block_guide li.li_deliv_date p {
	margin-top: -1.5em;
	margin-left: 20px;
}
div.block_guide div.guide_deliv_map {
	margin-top: -130px;
	margin-left: 100px;
}
#guide_deliv p.impact {
	font-size: 15px;
	font-weight: bold;
	padding-top: 10px;
}
div.block_guide .guid_item_recycle > div {
	margin-top:10px;
	padding: 3px;
	width:640px;
}

#guide_fee table.deliv_fee {
	border-collapse: collapse;
	border: solid 2px #f0f0f0;
}
#guide_fee table.deliv_fee th {
	background-color: #f0f0f0;
	padding: 8px;
	font-size: 13px; /* min */
	text-align: center;
}
#guide_fee table.deliv_fee tr td {
	padding: 8px;
	font-size: 13px; /* min */
	border: solid 2px #f0f0f0;
}

#guide_payment table.banks {
	border-collapse: collapse;
	border: solid 3px #f0f0f0;
}
#guide_payment table.banks tr.row2 td {
	background-color: #f0f0f0;
}
#guide_payment table.banks tr td {
	padding: 8px;
	font-size: 14px;
}
#guide_payment ul.banks_point li {
	margin-top: 8px;
}

#guide_fee table.fee {
	border-collapse: collapse;
	border: solid 2px #f0f0f0;
}
#guide_fee table.fee th {
	background-color: #f0f0f0;
	padding: 8px;
	font-size: 13px; /* min */
}
#guide_fee table.fee tr td {
	padding: 8px;
	font-size: 13px; /* min */
	border: solid 2px #f0f0f0;
}
#guide_fee table.fee th.fee1 {
	width: 250px;
}
#guide_fee table.fee th.fee2 {
	width: 150px;
}
#guide_payback h4{
	color: #ee446f;
	font-weight: bold;
}
#guide_payback ul.guide_payback_point li {
	margin-top: 10px;
}

/* 個人情報入力
------------------------- */

#page_entry .guest_title {
  width: 650px;
  height: 32px;
  background: url("../img/shopping_login/guest_title.gif");
  text-align: right;
  padding: 7px 10px 0 0;
}
#page_shopping_nonmember .guest_title {
  width: 940px;
  height: 32px;
  background: url("../img/shopping_login/guest_title.gif");
  text-align: right;
  padding: 7px 10px 0 0;
}

#page_entry .information,
#page_forgot .information,
#page_forgot_complete .information,
#page_shopping_nonmember .information {
  font-weight: bold;
  margin-top: 15px;
}

#page_entry .information span,
#page_shopping_nonmember .information span {
  font-weight: bold;
  color: #ee446f;
}


body.other table.registtable,
#page_contact .registtable,
#page_entry .registtable,
#page_forgot .registtable,
#page_shopping_shipping_edit .registtable,
#page_mypage_change .registtable,
#page_mypage_delivery_edit .registtable,
#page_mypage_delivery_new .registtable,
#page_mypage_change .registtable {
  /*width: 620px;*/
  width: 100%;
  background-color: #E1E1E1;
  border-collapse: separate;
  border-spacing: 0 5px;
  border: solid 5px #E1E1E1;
  border-width: 0 5px 0 5px;
  margin-top: 10px;
}
#page_shopping_nonmember .registtable {
  width: 950px;
  background-color: #E1E1E1;
  border-collapse: separate;
  border-spacing: 0 5px;
  border: solid 5px #E1E1E1;
  border-width: 0 5px 0 5px;
  margin-top: 10px;
}
#page_entry #popup_registtable {
	width: 100%;
}

body.other table.registtable td,
body.other table.registtable th,
#page_entry .registtable td,
#page_entry .registtable th,
#page_contact .registtable td,
#page_contact .registtable th,
#page_forgot .registtable td,
#page_forgot .registtable th,
#page_mypage_change .registtable td ,
#page_mypage_change .registtable th ,
#page_mypage_delivery_edit .registtable td,
#page_mypage_delivery_edit .registtable th,
#page_mypage_delivery_new .registtable td,
#page_mypage_delivery_new .registtable th,
#page_shopping_nonmember .registtable th,
#page_shopping_nonmember .registtable td,
#page_shopping_shipping_edit .registtable th,
#page_shopping_shipping_edit .registtable td,
#page_mypage_change .registtable th,
#page_mypage_change .registtable td {
  background-color: white;
  padding: 10px;
}

body.other table.registtable th,
#page_entry .registtable th,
#page_contact .registtable th,
#page_forgot .registtable th,
#page_mypage_change .registtable th ,
#page_mypage_delivery_edit .registtable th,
#page_mypage_delivery_new .registtable th,
#page_shopping_shipping_edit .registtable th,
#page_shopping_nonmember .registtable th {
  font-weight: bold;
  font-size: 14px;
}

body.other table.registtable td,
#page_entry .registtable td ,
#page_contact .registtable td ,
#page_forgot .registtable td ,
#page_mypage_change .registtable td ,
#page_mypage_delivery_edit .registtable td ,
#page_mypage_delivery_new .registtable td ,
#page_shopping_shipping_edit .registtable td,
#page_shopping_nonmember .registtable td {
  padding-left: 15px;
  background: url("../img/shopping_login/td_bg.gif") repeat-y 0 0 white;
  border: solid 3px white;
  border-width: 4px 0;
}

body.other table.registtable .no_color,
#page_entry .registtable .no_color,
#page_contact .registtable .no_color,
#page_forgot .registtable .no_color,
#page_mypage_change .registtable .no_color ,
#page_mypage_delivery_edit .registtable .no_color,
#page_mypage_delivery_new .registtable .no_color,
#page_shopping_shipping_edit .registtable .no_color,
#page_shopping_nonmember .registtable .no_color {
  background: transparent;
  border: none;
}

body.other #other_deliv_msg,
#page_entry #other_deliv_msg,
#shopping_login #other_deliv_msg {
  margin-top: 30px;
  font-size: 14px;
}

body.other #btn_block,
#page_entry #btn_block,
#page_contact #btn_block,
#page_shopping_nonmember #btn_block {
  clear: both;
  width: 730px;
  padding: 15px 0 10px 0;
  overflow: hidden;
  background-color: #E1E1E1;
  margin: 40px auto 20px auto;
}

body.other  #btn_block ul,
#page_entry  #btn_block ul,
#page_shopping_nonmember #btn_block ul {
  display: block;
  width: 530px;
  margin: 0 auto;
}

body.other  #btn_block ul li,
#page_entry  #btn_block ul li,
#page_shopping_nonmember #btn_block ul li {
  float: left;
  margin: 0 20px;
}

body.other div.btn_area,
#page_entry div.btn_area,
#page_entry_activate div.btn_area,
#page_contact div.btn_area,
#page_mypage_delivery_edit div.btn_area,
#page_mypage_delivery_new div.btn_area,
#page_mypage_change div.btn_area,
#page_mypage_change_complete div.btn_area {
	/*width: 620px;*/
	width: 100%;
	background-color: #ffffff;
}
#page_entry #popup_btn_area {
	width: 100%;
}


div.complete_box .title {
  width: 640px;
  height: 38px;
  background: url("../img/shopping_complete/title.gif");
  text-indent: -9999px;
}

div.complete_box  #complete_area {
  width: 620px;
  margin-top: 30px;
  border: solid 5px #E1E1E1;
  padding: 10px;
  text-align: center;
  overflow: hidden;
}

div.complete_box #complete_area #messages,
#page_shopping_complete #complete_area #messages {
  text-align: left;
}

div.complete_box #complete_area .message,
#page_shopping_complete #complete_area .message {
  font-size: 18px;
  font-weight: bold;
  color: #ee446f;
}

div.complete_box #complete_area p,
#page_shopping_complete #complete_area p {
  margin-top: 20px;
}

div.complete_box #complete_area .shop_information ,
#page_shopping_complete #complete_area .shop_information {
  float: right;
  text-align: right;
  margin-top: 20px;
}

div.complete_box div.btn_area {
  clear: both;
  /*width: 640px;*/
  width: auto;
  padding: 15px 0 10px 0;
  overflow: hidden;
  background-color: #E1E1E1;
  margin-top: 40px;
  margin-bottom: 20px;
  text-align: center;
  margin-left: auto;
  margin-right: auto;
}

#page_entry div#entry_complete_box div.btn_area {
  width: 640px;
}


body.other #box_left,
body.contact #box_left,
body.mypage #box_left {
	width: 250px;
	float: left;
}
body.other #box_main,
body.contact #box_main,
body.mypage #box_main {
	width: 670px;
	float: left;
	margin-left: 25px;
}
body#shopping_login #box_main {
	width: 955px;
	float: left;
	margin-left: 0px;
}
body.mypage #mypage_menu {
	width: 250px;
	margin-bottom: 10px;
}
body.mypage #mypage_menu div.block_inner {
	background-color: #F0F0F0;
	width: 235px;
	padding: 8px;
	-webkit-border-radius: 6px;
	-moz-border-radius: 6px;
	border-radius: 6px;
}
body.mypage #mypage_menu div.block_inner p.welcome {
	border-bottom: #cc0f71 2px solid;
	padding-bottom: 5px;
	padding-left: 3px;
}
body.mypage #mypage_menu div.block_inner h2 {
	margin-top: 6px;
	margin-bottom: 2px;
	font-size: 13px; /* min */
	padding-left: 3px;
}
body.mypage #mypage_menu div.block_inner ul li {
	padding-left: 13px;
	background: url('../img/common/icon_arrow.png') no-repeat 3px center;
	margin-bottom: 3px;
}

table.select_deliv_table {
	border-collapse: collapse;
	width: 100%;
}
table.select_deliv_table tr {
	border: solid 3px #f0f0f0;
}
table.select_deliv_table td {
	padding: 8px;
	vertical-align: top;
}
table.select_deliv_table td.no {
	vertical-align: middle;
	font-weight: bold;
	color: #ffffff;
	background-color: #cc0f71;
}
table.select_deliv_table td.button {
	vertical-align: middle;
}

body.other h3.subtitle,
body.mypage h3.subtitle {
  background: url("../img/common/icon_prefix_square4.gif") no-repeat 0 center;
  padding-left: 13px;
}

body.contact h2.title,
body.other h2.title,
body.mypage h2.title {
  width: 100%;
  height: 38px;
  text-indent: -9999px;
  margin-bottom: 10px;
}

h2#title_mypage_change {
	background: url("../img/common/title_mypage_change.png") no-repeat 0 center;
}
h2#title_mypage_refusal {
	background: url("../img/common/title_mypage_refusal.png") no-repeat 0 center;
}
h2#title_mypage_delivery {
	background: url("../img/common/title_mypage_delivery.png") no-repeat 0 center;
}
h2#title_contact {
	background: url("../img/common/title_contact.png") no-repeat 0 center;
}
h2#title_abouts {
	background: url("../img/common/title_abouts.png") no-repeat 0 center;
}
h2#title_guide_privacy {
	background: url("../img/common/title_guide_privacy.png") no-repeat 0 center;
}
h2#title_order {
	background: url("../img/common/title_order.png") no-repeat 0 center;
}
h2#title_user_data_recruit {
	background: url("../img/common/title_user_data_recruit.png") no-repeat 0 center;
}
h2#title_user_data_faq {
	background: url("../img/common/title_user_data_faq.png") no-repeat 0 center;
}
h2#title_flets {
	background: url("../img/common/title_flets.png") no-repeat 0 center;
}
h2#title_entry {
	background: url("../img/common/title_entry.gif") no-repeat 0 center;
	width: 100%;
	height: 38px;
	text-indent: -9999px;
	margin-bottom: 10px;
}

.add_address {
	text-align: right;
	margin-bottom: -1em;
}

#guide_modal {
	display: none;
}


#guide_all {
	margin-top: 20px;
	margin-bottom: 20px;
}
#guide_all>ul {
	border-bottom: 3px solid #cc0f71;
}
#guide_all>ul:after {
    display: block;
    clear: both;
    height: 0px;
    line-height: 0px;
    visibility: hidden;
    content: ".";
}

#guide_all>ul li {
	display: block;
	width: auto;
	float: left;
}
#guide_all>ul li.small {
	margin-top: 10px;
}
#guide_all #guide_all_area {
	border: solid 1px #323232;
	padding: 10px;
}
#guide_all #guide_all_area>div {
	display: none;
}
#guide_all #guide_all_area #guide_payback {
	display: block;
}


#qa h2 {
	background: url('../img/common/title_black_01.png') no-repeat;
	width: 307px;
	height: 46px;
	padding: 5px 0 0 8px;
}
.page_faq #qalist,
#qa #qalist {
	border-left: #323232 1px solid;
	border-right: #323232 1px solid;
	border-bottom: #323232 1px solid;
	margin-top: -14px;
	width: 300px;
}
.page_faq #qalist {
	width: 100%;
	margin-top: 10px;
	border-left: none;
	border-right: none;
	border-bottom: none;
}

.page_faq #qalist dl,
#qa #qalist dl,
#modal_qa #qalist dl {
	padding: 7px;
}
.page_faq #qalist dl.row1,
#qa #qalist dl.row1,
#modal_qa #qalist dl.row1{
	background-color: #f0f0f0;
}
.page_faq #qalist dl.row2,
#qa #qalist dl.row2,
#modal_qa #qalist dl.row2{
	background-color: #ffffff;
}
#qa #qalist div.button{
	text-align: center;
	padding: 6px 0 6px 0;
}
.page_faq #qalist dl dt,
#qa #qalist dl dt,
#modal_qa #qalist dl dt {
	text-indent: 20px;
	background: url('../img/common/icon_question.png') no-repeat left 2px;
	color: #cc0f71;
	font-size: 14px;
	font-weight: bold;
	margin-bottom: 5px;
}
.page_faq #qalist dl dd,
#qa #qalist dl dd,
#modal_qa #qalist dl dd {
	text-indent: 20px;
	background: url('../img/common/icon_answer.png') no-repeat left 2px;
}
div.crumbs {
	width: 950px;
	margin: 10px auto 0 auto;
}
div.crumbs li {
	display: inline;
	padding-right: 10px;
}
div.crumbs li:after {
 content: ">";
 padding-left: 10px;
}
div.crumbs li.last:after {
 content: none;
}

div.crumbs li span {
	font-weight: bold;
}

li.tab img {
    display: block;
}



a.tooltip {
    display: block;
    height: 100%;
    position: relative;
    text-decoration: none;
    width: 100%;
    z-index: 24;
}
a.tooltip:hover {
    z-index: 25;
}
a.tooltip span {
    display: none;
}
a.tooltip:hover span {
    background-color: #FFFFFF;
    border: 1px solid #999999;
    color: #333333;
    display: block;
    left: 40px;
    position: absolute;
    text-align: center;
    top: -10px;
    width: 150px;
}

div#header_infomation_news {
    display: block;
    margin: 10px auto 1px;
    position: relative;
    width: 900px;
    border: solid 2px #cc0f71;
    padding: 10px;
}
div#header_infomation_news p.title{
    font-weight: bold;
}
div#header_infomation_news span.date{
    font-weight: bold;
    color: #cc0f71;
}


div.guide_deliv_step {
  margin-top: 20px;
}
div.guide_deliv_step:after {
    display: block;
    clear: both;
    height: 0px;
    line-height: 0px;
    visibility: hidden;
    content: ".";
}
div.guide_deliv_step {
    display: block; /* for IE8 */
}


div.guide_deliv_step h3 {
  margin-bottom: 10px;
}
div.guide_deliv_step div.step_item {
  float: left;
  width: 302px;
  margin-left: 6px;
  margin-bottom: 10px;
  min-height: 240px;
  box-shadow: 2px 2px 3px rgba(0,0,0, 0.2);
  border: solid 1px #F0F0F0;
}
div.guide_deliv_step div.first {
  margin-left: 0px;
}
div.guide_deliv_step div.step_item div.image {
  padding: 1px;
  border: solid 1px #EAEAEA;
}
div.guide_deliv_step div.step_item div.image {
  padding: 1px;
  border: solid 1px #EAEAEA;
}
div.guide_deliv_step div.step_item div.syntax {
  padding: 8px;
}
div.guide_deliv_step span.title {
    font-weight: bold;
    color: #cc0f71;
}

/* banner 1_2
------------------------ */

div.ad_banner_1_2 {
    width: 950px;
    border: solid 4px #CC1072;
    background-color: #F9EFF8;
    height: 110px;
    position: relative;
    margin: 16px 0 0 0;
    float: left;
}
div.ad_banner_1_2 a {
    position: absolute;
    top: 50%;
    left: 50%;
    margin-left: -364px;
    margin-top: -45px;
}

/* banner 1_3
------------------------ */

div.ad_banner_1_3 {
    width: 728px;
    height: 90px;
    margin: 24px auto 0 auto;
}
div.ad_banner_1_3 a {
}

#page_forgot,
#page_forgot_complete,
#page_forgot_reset {
	 #box_main {
		margin: 0 auto !important;
		float: none !important;
	}
}

#window_area {
	margin: 0 auto;
}


/* ec-cube4系移行後
------------------------ */
%button {
	display: inline-block;
	padding: 5px 15px;
	border: solid 1px #ccc;
	background-color: #efefef;
}
.btn {
	&-default {
		@extend %button;
	}
}

.ec-cartRole{
	& &__error{
	  width: 100%;
	  text-align: center;
	  background-color: #de5d50;
	  .ec-alert-warning {
		max-width: 80%;
		display: inline-block;
		&__icon {
			vertical-align: bottom;
			display: inline-block;
			width: 14px;
			img {
				vertical-align: middle;
			}
		}
		&__text {
			display: inline-block;
			color: #fff;
			font-weight: bold;
			vertical-align: bottom;
			font-size: 14px;
		}
	  }
	}
}
  


