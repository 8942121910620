@charset "utf-8";

#page_mypage {
	.summary {
		width: 95%;
		margin: 6px auto 10px auto;
		background-color: #f0f0f0;
		font-weight: bold;
		padding: 5px 0 5px 8px;
	}
	.history_list {
		width: 100%;
		border-collapse:separate;
		border-spacing:2px;
		th {
			padding: 8px 0px 8px 0px;
		}
		td {
			padding: 14px 0px 14px 0px;
		}
		th {
			font-weight: normal;
			background-color: #f0f0f0;
			text-align: center;
		}
		th.background-color-white {
			background-color: #ffffff;
		}
		tr {
			background: url("../img/common/border_01.png") repeat-x left bottom;
		}
	}
}

#page_mypage_history {
	.title {
		text-align: left;
		text-indent: -9999px;
		height: 38px;
		background: url("../img/common/mypage_history.png") no-repeat;
	}

  h3 {
    background: url("../img/common/icon_prefix_square4.gif") no-repeat 0 center;
    padding-left: 10px;
	}

	.msg {
		font-weight: bold;
		font-size: 14px;
		letter-spacing: 1px;
		margin-top: 20px;
	}
	.infortable {
		width: 100%;
		background-color: #E1E1E1;
		border-collapse: separate;
		border-spacing: 0 5px;
		border: solid 5px #E1E1E1;
		border-width: 0 5px 0 5px;
		margin-top: 10px;
		margin-bottom: 30px;
		th,
		td {
			background-color: white;
			padding: 10px;
		}
		th {
			font-weight: bold;
			font-size: 14px;
			text-align: center;
		}
		td {
			padding-left: 15px;
			background: url("../img/shopping_login/td_bg.gif") repeat-y 0 0 white;
			border: solid 3px white;
			border-width: 4px 0;
		}
	}

	.carttable {
		width: 100%;
		border-collapse: separate;
		border-spacing: 5px;
		.productimage {
			width: 100%;
		}

	}

	.carttable th {
		background-color: #E1E1E1;
		font-weight: normal;
		padding: 5px;
	}

	.carttable th,
	.carttable td {
	 border-collapse: separate;
	}

	.carttable td a.productname {
		color: #0799FF;
	}

	.carttable td.separate_line {
		background: url("../img/cart/dashed.gif");
		height: 1px;
	}

	#totaltable {
		width: 245px;
		border-collapse: separate;
		border-spacing: 5px;
		float: right;
		margin-bottom: 30px;
	}

	#totaltable th,
	#totaltable td {
		border-collapse: separate;
		width: 110px;
		padding: 5px;
	}

	#totaltable th {
		background-color: #313131;
		color: white;
		text-align: center;
		font-size: 14px;
	}

	#totaltable td {
		background-color: #E1E1E1;
		font-weight: bold;
		font-size: 14px;
		text-align: right;
	}

	#totaltable span {
		color: #ee446f;
	}

	#btn_block {
		clear: both;
		width: 730px;
		padding: 15px 0 10px 0;
		overflow: hidden;
		background-color: #E1E1E1;
		margin: 0 auto;
	}

	#btn_block ul {
		display: block;
		width: 520px;
		margin: 0 auto;
	}

	#btn_block ul li {
		float: left;
		margin: 0 20px;
	}

	.ec-orderMail__link:hover {
		cursor: pointer;
	}

	.ec-orderMail__body {
		display: none;
		margin: 15px 0;
	}

	.ec-orderMail__close {
		margin-top: 15px;
		&:hover {
			cursor: pointer;
		}
	}
}

