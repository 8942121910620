@charset "utf-8";

/* products/list
----------------------------------------------- */
#page_product_list #box_left {
	width: 256px;
	float: left;
}
#page_product_list #box_main {
	width: 670px;
	float: left;
	margin-left: 25px;
}

#page_product_list #search {
	background-color: #f0f0f0;
	padding-bottom: 10px;
}
#page_product_list #search h2 {
	background: url('../img/common/title_black_01_left.png') no-repeat;
}
#page_product_list #search div.search_keyword,
#page_product_list #search div.search_price {
	margin-top: 10px;
	margin-left: 5px;
}
#page_product_list #search h3 {
	font-weight: normal;
	font-size: 13px; /* min */
	padding-left: 10px;
	background: url('../img/common/icon_prefix_square4_pink.png') no-repeat left 50%;
}
#page_product_list #search div.control {
	margin-top: 3px;
}
#page_product_list #search input {
	width: 210px;
	margin-right: 5px;
	height: 20px;
}
#page_product_list #search div.button {
	text-align: center;
	margin-top: 5px;
}

#page_product_list #product_list_area h2 {
	background-color: #cc0f71;
	color: #ffffff;
	padding: 13px 0 13px 10px;
}
#page_product_list div.product {
	width: 208px;
	float: left;
	position: relative;
	margin: 17px 0 0 22px;
}
#page_product_list div.first {
	margin: 17px 0 0 0px;
	clear: both;
}
#page_product_list div.product div.info {
	border: 2px solid #F0F0F0;
	padding: 2px;
}
#page_product_list div.product div.spec {
	background: #F0F0F0;
	padding: 2px;
}
#page_product_list div.product div.spec p.detail{
	text-align: right;
	margin-top: 10px;
}
#page_product_list div.product p.image {
	text-align: center;
	height: 150px;
	line-height: 150px;
	overflow: hidden;
}
#page_product_list div.product p.image img {
	vertical-align: middle;
	width: 70%;
}

#page_product_list div.product img.icon {
	position: absolute;
	top: -10px;
	left: -10px;
	z-index: 500;
}
#page_product_list div.product div.order p {
	margin-top: 3px;
}
#page_product_list select.quantity {
	width: 201px;
}
#page_product_list select.certificate {
	width: 201px;
}
#page_product_list select.quantity_value {
	width: 201px;
}
#page_product_list select.warranty_id {
	width: 201px;
}