#page_shopping_complete {
  .title {
    width: 950px;
    height: 38px;
    background: url("../img/shopping_complete/title.gif");
    text-indent: -9999px;
  }
  #complete_area {
    width: 920px;
    margin-top: 30px;
    border: solid 5px #E1E1E1;
    padding: 10px;
    text-align: center;
    overflow: hidden;
  }
  #btn_block {
    width: 730px;
    padding: 15px 0 10px 0;
    overflow: hidden;
    background-color: #E1E1E1;
    margin: 40px auto 20px auto;
    text-align: center;
  }
}