@charset "utf-8";

/* INDEX
----------------------------------------------- */
#page_product_detail {
	#box_main {
		width: 955px;
	}
	.product_status_radius_icon {
		background-color: #000000;
		color: #FFFFFF;
		display: inline-block;
		font-size: 10px;
		text-align: center;
		text-decoration: none;
		font-weight:bold;
		vertical-align:middle;
			-webkit-border-radius: 5px;
			-moz-border-radius: 5px;
			-o-border-radius: 5px;
			-khtml-border-radius: 5px;
			border-radius: 5px;
			border: none;
			padding:1px 3px 3px 3px;
		margin-left:2px;
		width:auto;
		height:15px;
	}
	.product_status_radius_icon:first-child {
		margin-left:12px;
	}
	.product_status_radius_icon.deliv_fee_free {
		background-color:#FFFF00;
		color:#FF0000;
	}
	#contents {
		#product_detail {
			width: 955px;
			margin-bottom: 20px;
			&:after {
					display: block;
					clear: both;
					height: 0px;
					line-height: 0px;
					visibility: hidden;
					content: ".";

			}
			#images {
				width: 440px;
				float: left;
			}
			#image {
				background-color: #ffffff;
				width: 440px;
				height: 331px;
				border: solid 1px #cc0f71;
				position: relative;
				z-index: 100;
			}
			#image img.pickup_mark {
				z-index: 99;
				position: absolute;
				top: 0px;
				left: 0px;
			}
			#image div.product_image {
				z-index: 98;
				position: absolute;
				top: 0;
				left: 0;
				height: 331px;
				width: 440px;
				overflow: hidden;
			}
			#image div.product_image p {
				line-height: 300px;
				text-align: center;
			}
			#image div.product_image img {
				vertical-align: bottom;
				width: 74%;
				height: auto;
			}

			#image div.detail_button {
				position: absolute;
				bottom: 5px;
				right: 5px;
			}
			#thumbs{
				margin-top: 10px;
			}
			#thumbs li{
				display: inline-block;
				border: 1px solid #323232;
				width: 105px;
				height: 105px;
			}
			#thumbs li:hover {
				border: 1px solid #cc0f71;
			}
			#thumbs li img{
				width: 104px;
				height: 104px;
			}
			#product_name {
				float: left;
				width: 512px;
				padding: 8px 0 8px 0;
				overflow: hidden;
				margin-top: 0px;
				margin-left: 0px;
				background: #cc0f71 url('../img/common/icon_name_arrow.png') no-repeat left 50%;
				z-index: 101;
				position: relative;
			}
			#product_name p {
				color: #ffffff;
				padding: 0 0 0 20px;
				font-size: 16px;
				font-weight: bold;
			}
			div.info {
				width: 502px;
				padding: 10px 0 0 10px;
				float: left;
			}
			p.description {
			}
			dl.attribute {
				margin-top: 8px;
			}
			dl.attribute dd {
				margin-top: -1.6em;
				margin-left: 100px;
			}
			dl.attribute dt.price {
				font-weight: bold;
				font-size: 16px;
			}
			dl.attribute dd.price {
				font-weight: bold;
				font-size: 16px;
				margin-top: -1.1em;
			}
			div.info_bottom {
				margin-top: 8px;
			}
			div.info_bottom:after {
					display: block;
					clear: both;
					height: 0px;
					line-height: 0px;
					visibility: hidden;
					content: ".";

			}
			div.certificate {
				float: left;
				width: 250px;
			}
			div.action {
				float: right;
				width: 240px;
			}
			div.action select {
				width: 200px;
			}
			div.action dl dd {
			}
			div.action dl dd {
				margin-top: -1.6em;
				margin-left: 30px;
			}
			div.action div.cartin {
				margin-top: 8px;
				text-align: right;
			}
		}

		#product_specs {
			margin-top: 20px;
			margin-bottom: 20px;
		}
		#product_specs>ul {
			border-bottom: 3px solid #cc0f71;
		}
		#product_specs>ul:after {
				display: block;
				clear: both;
				height: 0px;
				line-height: 0px;
				visibility: hidden;
				content: ".";
		}

		#product_specs>ul li {
			display: block;
			width: auto;
			float: left;
		}
		#product_specs>ul li.small {
			margin-top: 10px;
		}
		#product_specs #product_spec_area {
			border: solid 1px #323232;
			padding: 10px;
		}
		#product_specs #product_spec_area>div {
			display: none;
		}
		#product_specs #product_spec_area #guide_product {
			display: block;
		}
	}

	td {
		border:1px solid #333;
		padding:3px 4px;
	}
	th {
		border:1px solid #333;
		padding:3px 4px;
		background-color: #ccc;
	}
}