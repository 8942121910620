﻿@charset "utf-8";

/************************************************
 ブラウザリセット
************************************************ */
html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
font,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td {
    margin: 0;
    padding: 0;
    border: 0;
}

table,
caption,
th,
td {
    margin: 0;
    padding: 0;
    border: 0;
    border-collapse : collapse ;
    border-spacing: 0px;
    empty-cells: show;
    text-align: left;
    font-weight: normal;
}

a img,
iframe {
    border: none;
}
ol,
ul,
li {
    list-style: none;
}

input,
textarea,
select,
button {
    font-size: 100%;
    font-family: inherit;
}

button {
    background-color: transparent;
    border: none;
    cursor: pointer;
    outline: none;
    padding: 0;
    appearance: none;
}