@charset "utf-8";

body.mypage h2.title {
	text-align: left;
	text-indent: -9999px;
	height: 38px;
}
#mypage_history h2.title {
	background: url("../img/common/mypage_history.png") no-repeat;
}
