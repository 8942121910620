@charset "utf-8";

.user_data_recruit {
	div.message {
		font-size: 18px;
		font-weight: bold;
		margin-top: 10px;
	}
	h3.subtitle {
		margin-top: 20px;
	}
}