@charset "utf-8";

#page_entry,
#page_entry_activate,
#page_forgot {
	#box_left {
		width: 256px;
		float: left;
	}
	#box_main {
		width: 670px;
		float: left;
		margin-left: 25px;
	}

	#kiyaku textarea.kiyaku_text {
		width: 600px;
	}

	#kiyaku div.btn_area li {
		display: inline;
	}

	.privacy_check {
		text-align: center;
		margin: 30px 0;
	}
}