$mobile: 768px;
$desktop: 769px;

@mixin media_mobile(){
    @media only screen and (max-width: $mobile) {
        @content;
    }
}
@mixin media_desktop(){
    @media only screen and (min-width: $desktop) {
        @content;
    }
}

@mixin action_button() {
    #btn_block {
        width: 100%;
        box-sizing: border-box;
    }
    #btn_block ul {
        width: 100%;
        text-align: center;
    }
    #btn_block ul li {
        width: 40%;
        float: none;
        display: inline-block;
        margin: 0 2%;
        img {
            width: 100%;
        }
    }

}
  