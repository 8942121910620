@charset "utf-8";

@import "pages/reset";
@import "pages/common";

@import "pages/basic";
@import "pages/cart";
@import "pages/entry";
@import "pages/index";
@import "pages/guide_privacy";
@import "pages/mypage";
@import "pages/mypage_login";
@import "pages/mypage_history";
@import "pages/products_detail";
@import "pages/products_list";
@import "pages/shopping_login";
@import "pages/shopping_deliv";
@import "pages/shopping_confirm";
@import "pages/shopping_complete";
@import "pages/shopping_payment";
@import "pages/user_data_faq";
@import "pages/user_data_recruit";

@import "responsive/layout";

/*
@import "component/1.1.heading";
@import "component/1.2.typo";
@import "component/1.3.list";
@import "component/2.1.buttonsize";
@import "component/2.2.closebutton.scss";
@import "component/2.3.otherbutton";
@import "component/3.1.inputText";
@import "component/3.2.inputMisc";
@import "component/3.3.form";
@import "component/4.1.icon";
@import "component/5.1.grid";
@import "component/5.2.layout";
@import "component/6.1.login";
@import "component/7.1.itembanner";
@import "component/7.2.search";
@import "component/7.3.cart";
@import "component/8.1.info";
@import "component/8.2.banner";
@import "component/9.1.mypage";
@import "project/11.1.role";
@import "project/11.2.header";
@import "project/11.3.footer";
@import "project/12.1.slider";
@import "project/12.2.eyecatch";
@import "project/12.3.button";
@import "project/12.4.heading";
@import "project/12.5.topics";
@import "project/12.6.newItem";
@import "project/12.7.category";
@import "project/12.8.news";
@import "project/13.1.searchnav";
@import "project/13.2.shelf";
@import "project/13.3.pager";
@import "project/13.4.cartModal";
@import "project/14.1.product";
@import "project/15.1.cart";
@import "project/15.2.order";
@import "project/16.1.history";
@import "project/16.2.historyDetail";
@import "project/17.1.address";
@import "project/18.1.password";
@import "project/19.1.register";
@import "project/19.2.contact";
@import "project/19.3.customer";
@import "project/20.1.404";
@import "project/21.1.withdraw";
@import "project/22.1.editComplete";
*/
