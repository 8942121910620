@charset "utf-8";

#page_shopping,
#page_shopping_confirm {
  .ec-progress {
    margin-bottom: 40px;
  }
  h3 {
    background: url("../img/common/icon_prefix_square4.gif") no-repeat 0 center;
    padding-left: 10px;
  }

  .productimage {
    border: solid 2px #E1E1E1;
  }

  .title {
    width: 940px;
    height: 31px;
    background: url("../img/shopping_confirm/title.gif");
    text-align: right;
    padding: 7px 10px 0 0;
  }

  .carttable {
    width: 950px;
    border-collapse: separate;
    border-spacing: 5px;
    margin: 30px 0;
  }

  .carttable th {
    background-color: #E1E1E1;
    font-weight: normal;
    padding: 5px;
  }

  .carttable th#totalheader {
    background-color: #313131;
    color: white;
  }


  .carttable th,
  .carttable td {
    border-collapse: separate;
  }

  .carttable td#totalcell {
    background-color: #E1E1E1;
    padding: 0 10px;
    line-height: 200%;
    font-size: 14px;
  }

  .carttable td a.productname {
    color: #0799FF;
  }

  .carttable td.separate_line {
    background: url("../img/cart/dashed.gif");
    height: 1px;
  }

  .carttable td span.price {
    font-weight: bold;
    color: #ee446f;
  }

  .infortable {
    width: 950px;
    background-color: #E1E1E1;
    border-collapse: separate;
    border-spacing: 0 5px;
    border: solid 5px #E1E1E1;
    border-width: 0 5px 0 5px;
    margin-top: 10px;
    margin-bottom: 30px;
  }

  .infortable th,
  .infortable td {
    background-color: white;
    padding: 10px;
  }

  .infortable th {
    font-weight: bold;
    font-size: 14px;
    text-align: center;
  }

  .infortable td {
    padding-left: 15px;
    background: url("../img/shopping_login/td_bg.gif") repeat-y 0 0 white;
    border: solid 3px white;
    border-width: 4px 0;
  }


  #btn_block {
    clear: both;
    width: 730px;
    padding: 15px 0 10px 0;
    overflow: hidden;
    background-color: #E1E1E1;
    margin: 40px auto 20px auto;
  }

  #btn_block ul {
    display: block;
    width: 530px;
    margin: 0 auto;
  }

  #btn_block ul li {
    float: left;
    margin: 0 20px;
  }

  #check_rule {
    width: 800px;
    margin: 30px auto 0 auto;
    padding: 15px 0;
    text-align: center;
    font-size: 15px;
    font-weight: bold;
    border: solid 3px #ee446f; 
  }
  #check_rule div {
    margin-top: 10px;
  }
  #check_rule div label {
    color: #ee446f;
  }

  .check_rule_class {
    width: 800px;
    margin: 30px auto 0 auto;
    padding: 15px 0;
    text-align: center;
    font-size: 15px;
    font-weight: bold;
    border: solid 3px #ee446f; 
  }
  .check_rule_class div {
    margin-top: 10px;
  }
  .check_rule_class div label {
    color: #ee446f;
  }
}

p.warning {
	display: none;
}