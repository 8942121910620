@charset "utf-8";

#page_cart .alignC {
  text-align: center;
}

#page_cart .productimage {
  border: solid 2px #E1E1E1;
  width:130px;
  height: auto;
}


#page_cart .title {
  width: 940px;
  height: 32px;
  background: url("../img/cart/title.gif");
  text-align: right;
  padding: 7px 10px 0 0;
}

#page_cart .msg {
  font-weight: bold;
  font-size: 14px;
  letter-spacing: 1px;
  margin-top: 20px;
}

#page_cart .carttable {
  width: 950px;
  border-collapse: separate;
  border-spacing: 5px;
}

#page_cart .carttable th {
  background-color: #E1E1E1;
  font-weight: normal;
  padding: 5px;
}


#page_cart .carttable th,
#page_cart .carttable td {
 border-collapse: separate;
}

#page_cart .carttable td a.productname {
  color: #0799FF;
}

#page_cart .carttable td.separate_line {
  background: url("../img/cart/dashed.gif");
  height: 1px;
}

#page_cart #totaltable {
  width: 245px;
  border-collapse: separate;
  border-spacing: 5px;
  float: right;
  margin-bottom: 30px;
}

#page_cart #totaltable th,
#page_cart #totaltable td {
  border-collapse: separate;
  width: 110px;
  padding: 5px;
}

#page_cart #totaltable th {
  background-color: #313131;
  color: white;
  text-align: center;
  font-size: 14px;
}

#page_cart #totaltable td {
  background-color: #E1E1E1;
  font-weight: bold;
  font-size: 14px;
  text-align: right;
}

#page_cart #totaltable span {
  color: #ee446f;
}

#page_cart #btn_block {
  clear: both;
  width: 730px;
  padding: 15px 0 10px 0;
  overflow: hidden;
  background-color: #E1E1E1;
  margin: 0 auto;
}

#page_cart #btn_block ul {
  display: block;
  width: 520px;
  margin: 0 auto;
}

#page_cart #btn_block ul li {
  float: left;
  margin: 0 20px;
}


/* recommend
---------------------------------------*/

#page_cart #recommend {
  margin-top: 40px;
  width: 980px;
  padding: 15px 0;
  background: url('../img/common/border_footer.png') repeat-x;
}

#page_cart #recommend_inline {
  overflow: hidden;
}

#page_cart #recommend ul li {
  float: left;
}

#page_cart #icorecommend {
  margin-top: 20px;
}

#page_cart .rec_productblock {
  width: 255px;
  overflow: hidden;
  border: dashed 1px #858585;
  border-width: 0 1px 0 0;
  padding: 0 5px;
}

#page_cart .end {
  border: none !important;
}

#page_cart .rec_productimage {
  width: 130px;
  float: left;
  text-align: center;
}

#page_cart .rec_productimage .productimage {
  margin-bottom: 10px;
}

#page_cart .rec_productdetail {
  width: 115px;
  float: right;
}

#page_cart .ico_navi {
  margin-top: 65px;
}

#page_cart #recommend_footer {
  width: 100%;
  background: url('../img/common/border_footer.png') repeat-x;
  height: 5px;
  margin-top: 10px;
}

