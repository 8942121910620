@charset "utf-8";

/* ログイン
----------------------------------------*/

#page_shopping_login .title {
  width: 940px;
  height: 31px;
  background: url("../img/shopping_login/title.gif");
  text-align: right;
  padding: 7px 10px 0 0;
}

#page_shopping_login #shopping_login_block {
  width: 950px;
  overflow: hidden;
  margin-top: 15px;
}

#page_shopping_login #login_block {
  width: 450px;
  float: left;
  border: solid 1px;
  padding: 5px;
}

#page_shopping_login #email_block,
#page_shopping_login #password_block {
  padding: 15px 15px 0 15px;
}

#page_shopping_login #forget {
  float: right;
  margin-right: 15px;
  color: #0799FF;
  padding-left: 13px;
  background: url("../img/common/icon_arrow.png") no-repeat 0 center;
}

#page_shopping_login #log {
  clear: both;
  width: 220px;
  margin: 30px auto 0 auto;
  display: block;
}

#page_shopping_login #noaccount_block {
  width: 450px;
  float: right;
  border: solid 1px;
  padding: 5px;
}

#page_shopping_login #noaccount_block h4 {
  font-weight: bold;
}

#page_shopping_login #guest_block,
#page_shopping_login #regist_block {
  padding: 15px 15px 0 15px;
}

#page_shopping_login #regist_block {
  background: url('../img/common/border_footer.png') repeat-x;
}

#page_shopping_login .inputtext {
  margin: 5px 0 0 10px;
  font-size: 14px;
}

#page_shopping_login #guest_block #buystep,
#page_shopping_login #regist_block #btn_regist {
  width: 220px;
  margin: 15px auto;
  display: block;
}

#page_shopping_login .subtitle {
  background: url("../img/common/icon_prefix_square4.gif") no-repeat 0 center;
  padding-left: 10px;
  font-weight: normal;
}

#login_block input[type="email"],
#login_block input[type="password"] {
  width: 400px;
  padding: 2px;
  font-size: 16px;
}







